<template>
    <div>
        <div class="pb-6 diagonal pb-8 pt-0 pt-md-5" >
            <div class="row">
            </div>
        </div>

        <a-modal :title="'Priradiť preklad ' + newAssignTranslation.title" contentClassName="manager" class="builder-content" v-model="translationsModal" @ok="handleOk">
          <a-form-item  label="Vyberte si z existujúcich nepriradených stránok" type="inline" class="mb-0">
                <a-select v-model="newAssignTranslation.target" class="w-100">
                    <template v-for="i in translationstochose">
                        <a-select-option :key="i.value">{{i.label}}</a-select-option>
                    </template>
                </a-select>
    </a-form-item>
        </a-modal>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col"><h3 class="mb-0">Preklady</h3></div>
                                <div class="col text-right">
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :rowKey="record => record.target"
                                    :dataSource="data"
                                    :loading="loading"
                                    class="table-flush">
                                <template slot="name" slot-scope="record">
                                    <template v-if="!tid">


      <router-link :to="'/edit-content/'+ record.source" v-if="record.original" class="btn text-default btn-icon btn-secondary btn-sm">Upraviť</router-link>
      <a @click="removeSet(record.source,record.target)"  v-if="!record.original && record.target" class="btn ml-2 text-default btn-icon btn-secondary btn-sm">Odobrať priradenie</a>
<router-link :to="'/edit-content/'+ record.target"   v-if="!record.original && record.target" class="btn text-default btn-icon btn-secondary btn-sm" >Upraviť</router-link>

                                        <a @click="handleAISubmit(record.source,record.lang)"  class="btn text-default btn-icon btn-secondary btn-sm"  v-if="!record.target && !tid">Vytvoriť preklad pomocou AI</a>
<a @click="assignTranslation(record,record.lang)"  class="btn text-default btn-icon btn-secondary ml-2 btn-sm" v-if="!record.target && !tid">Priraď preklad</a>
<a @click="handleSubmit(record.source,record.lang)"  class="btn text-default btn-icon btn-secondary btn-sm" v-if="!record.target && !tid">Pridať preklad</a>

                                    </template>
                                    <template v-else>
                                        <sidebar-item :link="{name: 'Upraviť termín', path: '/'+ record.target}" v-if="record.original"/>
                                        <sidebar-item :link="{name: 'Upraviť termín', path: '/edit-taxonomy-item/'+ record.target + '/' + tid}" v-if="!record.original && record.target"/>
                                        <a @click="handleSubmitTax(record.source,record.lang,id)"  v-if="!record.target && tid">Pridať termín</a>
                                    </template>
                                </template>
                                <template slot="target" slot-scope="target,record">
                                    <template>
                                       <div v-if="record.original">Originál</div>
                                        <div v-else>{{target}}</div>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {dataService} from "../_services";
    import { Select, message, Modal} from "ant-design-vue"
    import AFormItem from "ant-design-vue/es/form/FormItem";
     import ASelect from "ant-design-vue/es/select";


    const columns = [
        {
            title: 'Typ obsahu',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Cieľ',
            dataIndex: 'target',
            scopedSlots: {customRender: 'target'},
        },
        {
            title: 'Jazyk',
            dataIndex: 'lang',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];

    export default {
        name: "translate",
        props: ["id","tid"],
        components: {
          "a-select": Select,
            "a-modal": Modal,
            ASelect,
            "a-select-option":ASelect.Option,
            AFormItem
        },
        data() {
            return {
                colorsto: JSON.parse(localStorage.colors),
                data: [],
                loading: false,
                nodes:[],
                translationstochose:[],
                newTranslation:{},
                newAssignTranslation:{},
                translationsModal:false,
                message,
                translationToSet:"",
                columns,
            };
        },
        mounted() {
            this.fetch();
        },
        methods: {
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },


            handleSubmitTax(source,lang,tid)
            {
                    dataService.axiosPost("translate/" + source + '/' + tid, {"tid": tid,"lang": lang}).then(results => {
                        this.fetch();
                    });

            },


            handleSubmit(source,lang)
            {

                    dataService.axiosPost("translate/" + source, {"lang": lang}).then(results => {
                        if (results.data) {
                            this.$router.push('/edit-content/' + results.data[0])
                        }
                    });

            },
            cancel() {
            },
            removeSet(source,target)
            {
              dataService.axiosFetch("removetranslation/" + this.id + '/' + target).then(results => {
                   this.fetch();
                    });
            },
            handleOk()
            {


              dataService.axiosPost("assigntranslation", this.newAssignTranslation).then(results => {
                this.translationsModal = false;
                   this.fetch();
                    });

            },
            assignTranslation(source,lang)
            {


              this.newAssignTranslation = JSON.parse(JSON.stringify(source));

              dataService.axiosPost("findlanguagecontent", {"lang": lang,"source": source}).then(results => {
                      this.translationstochose = results.data.data;
                      this.translationsModal = true;
                    });


            },
            handleAISubmit(source,lang)
            {
this.loading = true;
                    dataService.axiosPost("translateai/" + source, {"lang": lang}).then(results => {
                        if (results.data[0]) {
                          this.loading = false;
                           this.$router.push('/edit-content/' + results.data[0])
                        }
                        if(results.data.success == false)
                        {
                          message.error(results.data.message);
                        }
                    });

            },
            fetch() {
                this.loading = true;
                if(this.tid)
                {

                    dataService.axiosFetch(this.$options.name+ '/' + this.tid, this.id).then(results => {
                        this.data = results;
                        this.loading = false;
                    });
                }
                else
                {
                dataService.axiosFetch(this.$options.name,this.id).then(results => {
                    this.data = results;
                    this.loading = false;
                });
            }
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination-item.ant-pagination-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
